.productsWithFilter {
  padding: 10px 20px;
}
.sideOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eaecef;
  padding-right: 10px;
}
.searchInput {
  border: none;
  outline: none;
  padding: 10px;
  font-size: 0.9rem;
  width: 100%;
  background-color: #eaecef;
}
.search img {
  width: 20px;
  height: auto;
  object-fit: contain;
}
.sideActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.moodBtn button {
  border: 1px solid #878e95;
  border-radius: 4px;
  color: #878e95;
  font-size: 15px;
  padding: 3px 8px;
}

@media (max-width: 750px) {
  .productsWithFilter {
    padding: 0;
  }
}
