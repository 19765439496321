.homeScreen {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
}
.homeScreen::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(270deg, #000000 -2.92%, rgba(0, 0, 0, 0) 100%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  z-index: 5;
}
.homeScreen .image {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  object-fit: cover;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: 10;
  padding: 30px 70px;
  margin-top: 50px;
  color: #fff;
  margin-right: 20%;
}
.mainHead {
  color: #ffffff;
  display: none;
}
.mainHead h3 {
  font-size: 36px;
  font-weight: 400;
}
.mainImages {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
.mainImage {
  position: relative;
  border-radius: 2px;
  cursor: pointer;
}
.mainImage:last-of-type {
  display: none;
}
.mainImage::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(32, 13, 13, 0) 22.52%,
    rgba(32, 13, 13, 0.5) 71.17%
  );
  border-radius: 2px;
}
.mainImage img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 2px;
}
.imageText {
  position: absolute;
  bottom: 15px;
  left: 20px;
  color: #fff;
  white-space: nowrap;
}
.mainBtns {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.mainBtns button {
  border: 1px solid #fff;
  text-align: center;
  padding: 10px 20px;
  color: #fff;
  white-space: nowrap;
  border-radius: 5px;
  margin-right: 10px;
}

@media (max-width: 750px) {
  .homeScreen::before {
    background: linear-gradient(0deg, #000000 -2.92%, rgba(0, 0, 0, 0) 100%);
    /* transform: matrix(0, 1, -1, 0, 0, 0); */
  }
  /* .mainImages {
    display: none;
  } */
  .homeScreen {
    align-items: center;
  }
  .mainImage:last-of-type {
    display: block;
  }
  .main {
    margin-right: 0;
    padding: 30px 20px;
  }
  .mainBtns button {
    padding: 10px;
    font-size: 0.8rem;
  }
  .mainImages {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 50px;
  }
  .imageText {
    bottom: 10px;
    left: 10px;
    font-size: 0.9rem;
  }
}
