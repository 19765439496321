.container {
  /* height: 400px; */
  min-height: 100%;
  background-color: rgb(248, 247, 247);
  position: relative;
}
.image {
  height: auto;
  position: absolute;
  max-height: 100%;
  width: 100%;
  object-fit: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.controls {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 6;
  color: rgba(255, 255, 255, 0.5);
  font-size: 36px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.controls:hover {
  color: #fff;
  background-color: #000000;
}
.controls.prevIcon {
  left: 20px;
}
.controls.nextIcon {
  right: 20px;
}
.controls.hideIcon {
  visibility: hidden;
}

@media (max-width: 450px) {
  .container {
    height: 300px;
  }
  .controls {
    font-size: 20px;
  }
  .controls.prevIcon {
    left: 10px;
  }
  .controls.nextIcon {
    right: 10px;
  }
}
