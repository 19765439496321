.modelList {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding-bottom: 20px;
  grid-gap: 10px;
  padding-top: 20px;
}
.modelItem {
  cursor: pointer;
  background: #fff;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.modelItem.active::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 26px;
  height: 26px;
  background-color: var(--primary);
  border-radius: 4px 0 4px 0;
  color: #fff;
}
.modelItem.active::after {
  content: " ";
  position: absolute;
  left: 8px;
  top: 0;
  width: 8px;
  height: 18px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(50deg);
}
.modelImage {
  border: 1px solid #dfe2e6;
  border-radius: 4px;
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modelImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.modelLabel {
  color: #4a5056;
  margin-top: 10px;
  font-weight: 500;
}

@media (max-width: 990px) {
  .modelList {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 550px) {
  .modelList {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 400px) {
  .modelList {
    grid-template-columns: repeat(2, 1fr);
  }
}
