.imageGridContainer {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.imageGridItem {
  height: auto;
  margin: 0.4rem;
  cursor: pointer;
  position: relative;
  /* border-radius: 10px; */
}
.imageGridItem .playBtn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}
.imageGridItem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.imageModal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgb(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  padding-top: 50px;
}
.modalClose {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 1.5rem;
  color: #fff;
  cursor: pointer;
}
.modalClose:hover {
  transform: scale(1.2);
}
.modalContent {
  background-color: #fff;
  padding: 20px;
  height: 100%;
}
.actionBtn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  height: 150px;
  width: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  transform: translateY(-50%);
  font-size: 1.2rem;
  cursor: pointer;
}
.actionBtn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.prevBtn {
  left: 0;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
.nextBtn {
  right: 0;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.modalHead {
  color: rgb(121, 121, 126);
  font-size: 1.2rem;
  text-transform: capitalize;
  margin-bottom: 1rem;
}
.modalImage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--backgroundLight);
}
.modalImage img,
.modalImage video,
.modalImage embed {
  height: auto;
  max-height: 100%;
  width: 100%;
  object-fit: contain;
}
.modalImage embed {
  height: 100%;
  max-height: 100%;
  width: 100%;
  object-fit: contain;
}
.noDataDiv {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.noDataText {
  font-size: 2rem;
  font-weight: bolder;
  text-align: center;
}

.imageGridContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, auto-flow);
  grid-auto-flow: row dense;
}
.imageGridItem:first-child {
  grid-row: span 1;
}
.imageGridItem:nth-child(3n + 2) {
  /* grid-column: 2 / 3; */
  grid-row: span 2;
}
.imageGridItem:nth-child(3n + 1) {
  grid-column: span 2;
}
