.modalBody {
  max-height: 70vh;
  overflow: auto;
}
.modalHead {
  font-size: large;
  color: #878e95;
}

@media (max-width: 750px) {
  .modalBody {
    max-height: 80vh;
  }
}
