.navbar {
  padding: 30px 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* border-bottom: 1px solid rgb(224, 223, 223); */
  background-color: rgba(255, 255, 255, 0);
  z-index: 1500;
}
.logo {
  font-size: 24px;
  font-weight: bolder;
  text-transform: capitalize;
}
.logo .image {
  height: auto;
  /* width: 100%; */
  max-width: 150px;
  object-fit: contain;
}
.logo .defaultLogoStyle {
  object-fit: contain;
}
.midLine {
  width: 100%;
  margin: auto 50px;
  color: #dfe2e6;
}
.navList {
  display: flex;
  justify-content: center;
  align-items: center;
}
.navItem {
  /* margin-left: 1rem; */
  cursor: pointer;
  text-transform: capitalize;
  color: #ffffff;
  white-space: nowrap;
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
}
.hamburger {
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bolder;
}
.hamburger:hover {
  transform: scale(1.1);
}
.navBarHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

@media (max-width: 550px) {
  /* .navbar {
    padding: 10px;
  } */
  .navList {
    padding: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: var(--backgroundLight);
    position: absolute;
    flex-direction: column;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100vh;
    z-index: 6;
  }
  .hamburger {
    display: flex;
  }
  .navList.visible {
    display: flex;
  }
  .navList.invisible {
    display: none;
  }
}

@media (max-width: 750px) {
  .navbar {
    background-color: #fff;
    padding: 30px 20px;
  }
  .navItem {
    color: #000;
  }
  .midLine {
    display: none;
  }
}
