.dashboard {
  height: 100vh;
  width: 100%;
  display: flex;
  position: relative;
}
.dashHead {
  background-color: var(--backgroundSecondaryLight);
  padding: 50px 20px;
}

@media (max-width: 750px) {
  .dashboard {
    flex-direction: column-reverse;
  }
}
