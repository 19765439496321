.fullScreenError {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200;
  padding: 10px;
}
.errorMsg {
  padding: 10px;
  background-color: rgba(241, 179, 179, 0.3);
  border: 1px solid red;
  border-radius: 5px;
  color: rgb(243, 46, 46);
}
