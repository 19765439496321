.categoryList {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 10px;
}
.categoryItem {
  padding-left: 20px;
  padding-right: 20px;
}
.childrenOpened .categoryList {
  box-shadow: inset 0px 4px 7px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  background: #eaecef;
  padding-top: 20px;
  padding-bottom: 20px;
}
.categoryLabel {
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 18px;
}
.childrenOpened .categoryList .categoryItem:last-of-type .categoryLabel {
  margin-bottom: 0;
  padding-bottom: 0;
}
.imageContainer {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 8px;
}
.imageContainer input {
  position: absolute;
  z-index: 3;
  border-radius: 8px 0 8px 0;
  width: 26px;
  height: 26px;
  outline: none;
}
.image {
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
}

input[type="checkbox"]:checked {
  background: var(--primary);
  color: white;
}

input[type="checkbox"] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  background: #fff;
  /* border: 1px solid lightgray; */
  color: white;
}

input[type="checkbox"]:after {
  content: " ";
  position: relative;
  left: 40%;
  top: 20%;
  width: 20%;
  height: 40%;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(50deg);
  display: none;
}

input[type="checkbox"]:checked:after {
  display: block;
}
