.mainSection {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  width: 70%;
  position: relative;
}
.moveToTopIcon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  color: #fff;
  background-color: var(--primary);
  border-radius: 50%;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
  z-index: 6;
  display: none;
  cursor: pointer;
}
.views {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 6;
}
.viewItem {
  border: 1px solid #878e95;
  color: #fff;
  font-size: 15px;
  padding: 4px 8px;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  white-space: nowrap;
}
.viewItem:first-of-type {
  border-radius: 4px 0 0 4px;
}
.viewItem:nth-of-type(2) {
  border-right: none;
  border-left: none;
}
.viewItem:last-of-type {
  border-radius: 0 4px 4px 0;
}
.viewItem:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.viewItem.active {
  color: #fff;
  font-weight: bolder;
  background-color: rgba(0, 0, 0, 0.6);
}
.model {
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  position: relative;
  background-color: var(--backgroundSecondaryLight);
}
.productInfo {
  height: 30%;
}
.changeModaBtn {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 3;
}
.changeModaDropDown {
  border-radius: 1.5rem;
  position: absolute;
  top: 0px;
  color: var(--primary);
  right: 2.5rem;
  z-index: 3;
  border: none;
  outline: none;
  font-size: 24px;
}
.galleryBtn {
  position: absolute;
  left: 50%;
  bottom: 20px;
  text-align: center;
  transform: translateX(-50%);
  cursor: pointer;
  animation: galleryBtnAnimate 0.7s ease-in-out infinite alternate-reverse;
}

@keyframes galleryBtnAnimate {
  from {
    transform: translateY(0) translateX(-50%);
  }
  to {
    transform: translateY(-5px) translateX(-50%);
  }
}

.galleryOverlay {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 0%;
  z-index: 8;
  background: rgba(242, 242, 242, 0.9);
  backdrop-filter: blur(35px);
  animation: galleryOverlayAnim 0.2s ease-in-out forwards;
  overflow: auto;
}
@keyframes galleryOverlayAnim {
  from {
    height: 0%;
    top: 100%;
  }
  to {
    height: 100%;
    top: 0;
  }
}
.galleryCloseIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #aeb5bd;
  font-size: large;
  padding: 20px;
}
.galleryCloseIcon i {
  cursor: pointer;
}
.gallery {
  width: 100%;
  height: 100%;
  background-color: transparent;
  overflow: auto;
  /* position: relative; */
}
.prevIcon,
.nextIcon {
  position: absolute;
  top: 50%;
  z-index: 3;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 2rem;
  opacity: 0.8;
}
.prevIcon {
  left: 20px;
}
.nextIcon {
  right: 20px;
}
.prevIcon:hover,
.nextIcon:hover {
  opacity: 1;
}

.noDataDiv {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.noDataText {
  font-size: 2rem;
  font-weight: bolder;
  text-align: center;
}
.productView {
  width: 100%;
  height: 100%;
  background-color: var(--backgroundLight);
}
.heroImage {
  position: relative;
}
.heroImage::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  background: linear-gradient(
    180deg,
    rgba(241, 243, 246, 0.01) 95%,
    rgba(255, 255, 255, 0.3) 100%
  );
}
.productName {
  color: #4a5056;
  text-transform: capitalize;
}
.prodDesc {
  color: #878e95;
}
.prodPrice {
  text-align: right;
}
.prodPriceValue {
  /* color: #4a5056; */
  color: var(--primary);
  font-size: 36px;
}
.prodPriceText {
  font-weight: 300;
  font-size: 20px;
  color: #aeb5bd;
  margin-left: 5px;
}
.bgBlurredImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  z-index: 0;
}
.modalHead {
  font-size: large;
  color: #878e95;
}
.noScroll {
  overflow: hidden;
}

.viewGalleryBtn {
  display: none;
}
.bottomProductsList {
  display: none;
  border-top: 1px solid #cfd4d9;
  margin-top: 20px;
  padding-top: 20px;
}
.mainSectionNavbar {
  display: none;
}
.mainSectionFilters {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  overflow-y: auto;
  background: rgba(242, 242, 242, 0.95);
  backdrop-filter: blur(35px);
  z-index: 7;
  border-radius: 0px 0px 10px 10px;
  display: none;
  animation: filterAnim 0.5s ease-in-out forwards;
}
@keyframes filterAnim {
  from {
    height: 0;
  }
  to {
    height: 100vh;
  }
}

@media (max-width: 750px) {
  .mainSection {
    width: 100%;
  }
  .mainSectionNavbar,
  .mainSectionFilters {
    display: block;
  }
  .moveToTopIcon {
    display: flex;
  }
  .viewGalleryBtn {
    display: block;
  }
  .galleryBtn {
    display: none;
  }
  .bottomProductsList {
    display: block;
  }
}
@media (max-width: 575px) {
  .prodPrice {
    text-align: left;
  }
}
