/* Reset CSS For The Container */
.container,
.container span,
.container h1,
.container h2,
.container h3,
.container h4,
.container h5,
.container h6,
.container p,
.container blockquote {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  line-height: 1 !important;
}

.container {
  all: unset;
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  background-color: var(--backgroundDark);
  border: solid 1px var(--backgroundLight);
  font-size: 18px;
  font-family: Verdana, sans-serif;
}

.loaderContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

@media (max-width: 750px) {
  .container {
    /* height: calc(100vh - 150px); */
  }
}
