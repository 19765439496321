.productList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  overflow-x: auto;
}
.productItem {
  cursor: pointer;
  width: 100%;
}
.productItem:hover .productImage {
  transform: scale(1.05);
}
.productItem.noHover:hover .productImage {
  transform: none;
}
.productItem.active .productImage {
  border: 2px solid var(--primary);
}
.productItem.active:hover .productImage {
  transform: none;
}

.productItem {
  padding: 10px;
  box-sizing: border-box;
  /* overflow: auto; */
  max-width: 100%;
}

.productImage {
  width: 100%;
  position: relative;
  padding-bottom: 100%;
  border-radius: 50%;
}

.productImage img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  border: 2px solid #fff;
}

.productName {
  font-size: 1rem;
  text-transform: capitalize;
  text-align: center;
}

@media (max-width: 900px) {
  .productList {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 750px) {
  .productList {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (max-width: 620px) {
  .productList {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 500px) {
  .productList {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 350px) {
  .productList {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 750px) {
  .modalProductList {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 900px) {
  .productList {
    grid-template-columns: repeat(3, 1fr);
  }
  .modalProductList {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 1300px) {
  .productList {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 1150px) {
  .modalProductList {
    grid-template-columns: repeat(5, 1fr);
  }
}
