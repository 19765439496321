.authScreen {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 70vh;
}
.authContainer {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  border: 1px solid lightgray;
}
