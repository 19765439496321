.container {
  height: 100vh;
  overflow: none;
  width: 100%;
  background-color: var(--backgroundSecondaryLight);
  padding-bottom: 20px;
}
.topBar {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 80px;
  border-bottom: 1.5px solid var(--primary);
}
.topBar h5 {
  font-weight: bold;
}
.leftTopBar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.rightTopBar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.moodboardContainer {
  background-color: #fff;
  height: calc(100vh - 80px);
  overflow-y: hidden;
  width: 100%;
  position: relative;
  padding: 20px;
  padding-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.leftControls,
.rightControls {
  position: absolute;
  top: 10px;
  z-index: 5;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.leftControls {
  left: 10px;
}
.rightControls {
  right: 10px;
}
.rightTopBar input {
  border: 1px solid var(--primary);
  height: 33px;
  width: 33px;
  /* display: none; */
}
.logo {
  font-size: 24px;
  font-weight: bolder;
  text-transform: capitalize;
}
.logo .logoImage {
  height: auto;
  /* width: 100%; */
  max-width: 150px;
  object-fit: contain;
}
.logo .defaultLogoStyle {
  object-fit: contain;
}
.imagesContainer {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, auto-flow);
  grid-auto-flow: row dense;
  grid-gap: 10px;
}
.imageContainer {
  height: 100%;
  width: 100%;
  max-height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  cursor: pointer;
  border-radius: 20px;
  position: relative;
}
.imageContainer:nth-of-type(1),
.imageContainer:nth-of-type(3),
.imageContainer:nth-of-type(4) {
  grid-row: span 2;
}
.image,
.pngImage {
  height: 100%;
  max-height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
}
.pngImage {
  position: absolute;
  top: 0;
  left: 0;
}
.emptySlot {
  color: grey;
  height: 100%;
  width: 100%;
  background-color: lightgrey;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-size: 48px;
}

@media (max-width: 450px) {
  .topBar h5 {
    font-size: 16px;
    display: none;
  }
  .emptySlot {
    font-size: 24px;
  }
}
@media (min-width: 700px) {
  .imageContainer:nth-of-type(1),
  .imageContainer:nth-of-type(3),
  .imageContainer:nth-of-type(4) {
    max-height: calc(calc(calc(100vh - 140px) / 3) * 2);
  }
  .imageContainer:nth-of-type(2),
  .imageContainer:nth-of-type(5),
  .imageContainer:nth-of-type(6) {
    max-height: calc(calc(calc(100vh - 140px) / 3) * 1);
  }
}
