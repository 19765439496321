.sidebar {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: 30%;
  background: #f8f9fa;
  position: relative;
}

.filtersView {
  position: relative;
  height: 0%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 15;
  background-color: transparent;
  animation: animate-filter-view 0.3s ease-in-out forwards;
}

@keyframes animate-filter-view {
  from {
    height: 0%;
  }
  to {
    height: 100%;
  }
}
.filtersHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 20px;
  margin-bottom: 20px;
  margin-top: 8px;
  font-size: 22px;
  font-weight: 400;
  color: #000;
}

@media (max-width: 750px) {
  .sidebar {
    display: none;
  }
}

.noScroll {
  overflow: hidden;
}
