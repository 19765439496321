.sideHead {
  border-bottom: 1.5px solid #e5e5e5;
}
.sideHead,
.sideOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: auto auto 24px auto; */
}
.logo {
  font-size: 24px;
  font-weight: bolder;
  text-transform: capitalize;
}
.logo .image {
  height: auto;
  /* width: 100%; */
  max-width: 150px;
  object-fit: contain;
}
.logo .defaultLogoStyle {
  object-fit: contain;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1.5px solid #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  color: grey;
}
.toast {
  position: absolute;
  /* padding: 10px; */
  background-color: #fff;
  border: 1px solid lightgray;
  z-index: 4;
  top: 100%;
  right: 0;
  border-radius: 5px;
  color: #000;
}
.toast ul {
  padding: 0;
  margin: 0;
}
.toast ul li {
  padding: 5px 16px 5px 8px;
}

.noScroll {
  overflow: hidden;
}

.filterContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filterIcon {
  font-size: 24px;
  color: #000;
  cursor: pointer;
}
.filterIcon img {
  width: 25px;
  height: 25px;
}
@media (max-width: 750px) {
}
