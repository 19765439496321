:root {
  --primaryColor: rgb(20, 181, 36);
  --primary: rgb(20, 181, 36);
  --primaryDark: rgb(20, 181, 36);
  --primaryLight: #91dcaf;
  --backgroundDark: #141516;
  --backgroundSecondaryDark: #202020;
  --backgroundLight: #ffffff;
  --backgroundSecondaryLight: rgb(226, 226, 229);
  --fontColor: #000000;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
}
body {
  background-color: var(--backgroundLight);
}
a {
  text-decoration: none;
}
ul,
li {
  list-style: none;
}
button {
  background: none;
  outline: none;
  border-radius: 5px;
  border: none;
  padding: 8px 16px;
  text-transform: capitalize;
  transition: background-color 0.3s, opacity 0.3s;
}
.btnPrimary {
  color: #fff;
  border-radius: 5px;
  background-color: var(--primaryColor);
}
.btnPrimary:hover {
  background-color: var(--primaryDark);
}
.btnOutlinePrimary {
  border-radius: 5px;
  border: 2px solid var(--primaryColor);
  color: var(--primaryColor);
}
.btnOutlinePrimary:hover {
  color: #fff;
  background-color: var(--primaryColor);
}

.styledScrollbar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
}
.styledScrollbar::-webkit-scrollbar-thumb {
  background: var(--backgroundSecondaryLight);
  border-radius: 2rem;
}
.transparentScrollbar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
}
.transparentScrollbar::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 2rem;
}
.noScroll {
  overflow: none;
}

.lazyLoad {
  background-color: var(--backgroundSecondaryLight);
  opacity: 1;
  animation: lazyLoadAnim 0.5s ease-in-out infinite alternate-reverse;
}
@keyframes lazyLoadAnim {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fullFlexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.normalLink {
  text-decoration: none;
  color: black;
}
.normalLink:hover {
  color: black;
}
.centerLoader {
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.4);
}

.observedText {
  position: relative;
  opacity: 0;
  transition: all 0.5s;
  left: -100px;
}
.showObservedText {
  opacity: 1;
  left: 0;
}
.text-fav-grey {
  color: rgb(212, 214, 216);
}
.pointer {
  cursor: pointer;
}
.primaryLink {
  color: var(--primary);
  text-decoration: none;
}
.primaryLink:hover {
  color: var(--primary);
  text-decoration: underline;
}
.customOutlineBtn {
  border: 1px solid #878e95;
  border-radius: 4px;
  color: #878e95;
  font-size: 15px;
  padding: 3px 8px;
}
