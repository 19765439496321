.landingScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.landingScreen span {
  -webkit-text-stroke: 2px var(--primary);
  color: #fff;
  font-size: 5rem;
  position: relative;
}
.landingScreen span::before {
  content: attr(data-content);
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 100%;
  border-right: 3px solid var(--primary);
  /* background-color: black; */
  overflow: hidden;
  color: var(--primary);
  animation: animate 2s steps(7) infinite;
}
@keyframes animate {
  0%,
  10%,
  100% {
    width: 0%;
  }
  70%,
  90% {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .landingScreen span {
    font-size: 3rem;
  }
}
