.loginTypeGroup {
  display: flex;
  justify-content: space-around;
}
.loginType {
  /* margin-right: 1rem; */
  cursor: pointer;
  /* border: 1px solid lightgray; */
  /* border-radius: 5px; */
  padding: 5px;
  color: black;
  font-size: 1.2rem;
}
.loginType:hover {
  /* border: 1px solid var(--primary); */
  color: var(--primary);
}
.loginType.active {
  /* background-color: var(--primary); */
  text-decoration: underline;
  /* border: 1px solid var(--primary); */
  color: var(--primary);
}
.nextIcon {
  font-size: 3rem;
  color: var(--primary);
}
