.aboutScreen {
  height: 100%;
  width: 100%;
}
.aboutContainer {
  padding: 3rem;
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
}
.aboutContent {
  z-index: 10;
}
.aboutContainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  background: linear-gradient(
    270deg,
    #ffffff 0%,
    rgba(196, 196, 196, 0.87) 34.51%,
    rgba(0, 0, 0, 0) 100%
  );
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.aboutContainer .bgImage {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  object-fit: cover;
}
.bio {
  color: #353a3f;
  margin-top: 100px;
  width: 80%;
}
.bio h3 {
  font-weight: 500;
}
.picHead {
  font-weight: 500;
  font-size: 50px;
  line-height: 36px;
  color: #353a3f;
  margin-bottom: 2rem;
}
.imageGrid {
  padding: 3rem;
  height: 100%;
  width: 80%;
}
.viewCatalogBtn {
  text-transform: uppercase;
  padding: 10px 22px;
  font-size: 0.9rem;
  border-radius: 38px;
  color: #000;
  border-color: #000;
  transition: all 0.3s;
}
.viewCatalogBtn:hover {
  color: #fff;
  background-color: #000;
}
.logo .image {
  height: auto;
  /* width: 100%; */
  max-width: 150px;
  object-fit: contain;
}
.logo .defaultLogoStyle {
  object-fit: contain;
}
.galleryBtn {
  position: absolute;
  left: 50%;
  bottom: 20px;
  text-align: center;
  transform: translateX(-50%);
  cursor: pointer;
  z-index: 10;
  animation: galleryBtnAnimate 0.7s ease-in-out infinite alternate-reverse;
}

@keyframes galleryBtnAnimate {
  from {
    transform: translateY(0) translateX(-50%);
  }
  to {
    transform: translateY(-5px) translateX(-50%);
  }
}

@media (max-width: 750px) {
  .bio,
  .imageGrid {
    width: 100%;
  }
}
